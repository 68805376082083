<template>
  <div>
    <navbar></navbar>
    <v-container>
      <v-row no-gutters class="area ma-auto">
        <v-col cols="4" xs="4" sm="3" md="2" lg="2" xl="1" v-for="(item, index) in cards" :key="index"
          :class="permissionExist('#view_' + item.tag) == true ? 'm-auto' : 'd-none'">
          <v-card style="height:150px;justify-content:center;align-items:center;display:flex;" outlined elevation="2"
            link :to="item.route.replace('dashboard/', '')" shaped class="card mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <div class="fs-small nzar">
                  <center>
                    <h3 v-if="lang() === 'ku'">{{ item.ku_name }}</h3>
                    <h3 v-if="lang() === 'ar'">{{ item.ar_name }}</h3>
                    <h3 v-if="lang() === 'en'">{{ item.en_name }}</h3>
                  </center>
                </div>
                <center>
                  <v-list-item-avatar tile size="50" class="rounded ma-3">
                    <v-icon class="text-main" large>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                </center>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="mt-16"></div>
    <dashboardFooter />
  </div>
</template>

<script>
import navbar from "../../layouts/dashboard/nav.vue";
import NavRequests from "../../requests/dashboard/Nav";
import dashboardFooter from "../../layouts/dashboard/dashboardFooter.vue";
export default {
  data() {
    return {
      cards: [],
    };
  },
  components: {
    navbar,
    dashboardFooter,
  },
  methods: {

    authCheck() {
      NavRequests.checkAuth(
        {
          isAuthCheck: true,
          user_id: this.dashboardAuthId(),
          token: this.dashboardAuthToken(),
        },
        (res) => {
          if (res.data.status === false) {
            this.$router.push("/dashboard/auth/login");
          } else if (res.data.status === true) {
            this.$store.state.dashboardauth = res.data.data;
            this.menus = res.data.menus
            this.avatar = this.$store.state.dashboardauth.avatar;
            this.username = this.$store.state.dashboardauth.name;
            this.logo = this.$store.state.setting.logo;
          }
        }
      );
    },

    fetchCards() {
      this.cards = this.$store.state.pages
    }
  },
  mounted() {
    this.fetchCards()
    this.authCheck()
  },
};
</script>


<style scoped>
@font-face {
  font-family: "nzar";
  src: local("nzar"), url(../../assets/fonts/nzar.ttf) format("truetype");
}

.radius-rounded {
  border-radius: 15px !important;
}

.nzar {
  font-family: "nzar" !important;
}

.fs-small {
  font-size: 0.9rem;
}

.theme--dark .dashboard .home-card:hover {
  background: #393b56 !important;
  transition: ease 0.2s !important;
}

.theme--light .dashboard .home-card:hover {
  background: #eceaea !important;
  transition: ease 0.2s !important;
}

@media only screen and (max-width: 602px) {
  .card {
    zoom: 90% !important;
  }
}


@media (min-width:1264px) and (max-width:1903px) {
  .card {
    width: 90% !important;
    zoom: 95% !important;
  }

  .area {
    width: 85%;
  }

  h3 {
    font-size: 1.1rem;
  }
}

@media (min-width:960px) and (max-width:1263px) {
  .card {
    width: 90% !important;
    zoom: 95% !important;
  }

  .area {
    width: 90%;
  }

  h3 {
    font-size: 1.1rem;
  }
}


@media (min-width:650px) and (max-width:959px) {
  .card {
    width: 90% !important;
    zoom: 95% !important;
  }

  .area {
    width: 80%;
  }

  h3 {
    font-size: 1.1rem;
  }
}


@media (min-width:500px) and (max-width:649px) {
  .card {
    width: 90% !important;
    zoom: 95% !important;
  }

  .area {
    width: 85%;
  }

  h3 {
    font-size: 1.1rem;
  }
}
</style>