<template>
    <div>
        <navbar></navbar>
        <v-container class="mt-4 mb-4 p-4">
            <v-row>
                <v-col cols="10" sm="10" md="4" lg="3" xl="3" class="mx-auto mode--text">
                    <v-card outlined>
                        <center>
                            <h1 class="mb-2 mt-2 pa-2 rounded">{{ langkeyword("CURRENCYEXCHANGE") }}</h1>
                        </center>
                    </v-card>
                </v-col>
            </v-row>


            <v-row>

                <v-col cols="12" sm="6" md="4" lg="6" xl="4">
                    <v-card elevation="2" outlined shaped class="ma-auto text-center">
                        <v-text-field class="ma-2 p-2" style="font-size:1.6rem;" type="number" min="0"
                            :label="langkeyword('IQD')" :placeholder="langkeyword('AMOUNT')" clearable filled
                            v-model="usdPrice"></v-text-field>
                        <v-btn v-if="usdPrice != 0 && permissionExist('#change_currencyexchange')"
                            @click="changeUsdPrice" color="green" x-large class="white--text ma-2 pa-2 text-center">{{
                                langkeyword('CHANGE') }}</v-btn>

                        <h2>{{ dinar(usdPrice) }}</h2>

                    </v-card>
                </v-col>


                <v-col cols="12" sm="6" md="4" lg="6" xl="4">
                    <v-card elevation="2" outlined shaped class="ma-auto text-center">
                        <v-text-field class="ma-2 p-2" style="font-size:1.6rem;" type="number" min="0"
                            :label="langkeyword('USD')" :placeholder="langkeyword('AMOUNT')" clearable filled
                            v-model="usdAmount"></v-text-field>
                        <v-btn @click="usdToIqd" color="green" x-large class="white--text ma-2 pa-2 text-center">{{
                            langkeyword('USD_TO_IQD') }}</v-btn>

                        <h2>{{ dinar(iqdResult) }}</h2>

                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="6" xl="4">
                    <v-card elevation="2" outlined shaped class="ma-auto text-center">
                        <v-text-field class="ma-2 p-2" style="font-size:1.6rem;" type="number" min="0"
                            :label="langkeyword('IQD')" :placeholder="langkeyword('AMOUNT')" clearable filled
                            v-model="iqdAmount"></v-text-field>
                        <v-btn @click="iqdToUsd" color="green" x-large class="white--text ma-2 pa-2 text-center">{{
                            langkeyword('IQD_TO_USD') }}</v-btn>

                        <h2>{{ dollar(usdResult) }} </h2>

                    </v-card>
                </v-col>


            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>
<script>
import CurrencyExchangeRequests from "../../../requests/dashboard/accounting/CurrencyExchange";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import Vue from "vue";
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        usdPrice: 0,
        usdAmount: 0,
        iqdAmount: 0,
        usdResult: 0,
        iqdResult: 0,
    }),
    watch: {
        options: {
            handler() { },
        },
    },
    mounted() {
        setTimeout(() => {
            if (!this.permissionExist("#view_currencyexchange")) {
                this.$router.push("/dashboard/home");
            }
            this.usdPrice = this.$store.state.currencies.filter(c => {
                return c.id == 2
            })[0].price
        }, 500);

    },
    computed: {

    },
    methods: {
        changeUsdPrice() {
            CurrencyExchangeRequests.changeUsd(
                {
                    changeUsd: true,
                    price: this.usdPrice,
                    user_id: Vue.prototype.dashboardAuthId(),
                },
                (res) => {
                    this.$store.state.currencies = res.data.data;
                    this.usdPrice = res.data.price
                }
            );
        },
        usdToIqd() {
            this.iqdResult = this.usdAmount * (this.usdPrice / 100)
        },
        iqdToUsd() {
            this.usdResult = (100 / this.usdPrice) * this.iqdAmount
        },

    },
};
</script>