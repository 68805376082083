<template>
  <div>
    <navbar v-if="shownavandfooter === true"></navbar>
    <v-container fluid class="mt-4 mb-4">
      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="d-flex text-main justify-center">
        <v-col :cols="shownavandfooter === true ? '11' : '12'">
          <v-data-table :headers="headers" :items="customers" :loading="loading" :search="search" :sort-desc="true" sort-by="id" :class="shownavandfooter === true
            ? 'elevation-1 nzar-font radius-15 mode'
            : 'nzar-font radius-15'
            " :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar flat class="radius-15">
                <v-toolbar-title><v-icon>mdi-face-agent</v-icon>
                  {{ langkeyword("CUSTOMERS") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success"
                      :style="permissionExist('#add_customers') ? 'color: white;float:right' : 'display:none;'"
                      class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-text-field class="nzar-font" append-icon="mdi-format-title" v-model="customersObject.name"
                            :label="langkeyword('NAME')">
                          </v-text-field>
                          <v-text-field class="nzar-font" type="number" append-icon="mdi-format-title"
                            v-model="customersObject.phone" :label="langkeyword('PHONE')">
                          </v-text-field>

                          <v-text-field class="nzar-font" append-icon="mdi-format-title"
                            v-model="customersObject.address" :label="langkeyword('ADDRESS')">
                          </v-text-field>

                          <v-text-field class="nzar-font" type="number" append-icon="mdi-format-title"
                            v-model="customersObject.iqddebt" :label="langkeyword('BASICDEBT') +
                              '  ' +
                              langkeyword('IQD')
                              ">
                          </v-text-field>

                          <v-text-field class="nzar-font" type="number" append-icon="mdi-format-title"
                            v-model="customersObject.usddebt" :label="langkeyword('BASICDEBT') +
                              '  ' +
                              langkeyword('USD')
                              ">
                          </v-text-field>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCustomers">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                        @click="updateCustomers(customersObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="deleteCustomers(customersObject.id)">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field v-if="permissionExist('#search_customers')" class="nzar-font" v-model="search"
                      append-icon="mdi-magnify" :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_customers')" small class="mr-2 orange white--text px-2 py-2 rounded"
                @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#view_customers')" small class="mr-2 blue white--text px-2 py-2 rounded"
                @click="viewProfile(item)">
                mdi-eye
              </v-icon>
              <v-icon v-if="permissionExist('#delete_customers')" small class="mr-2 red white--text px-2 py-2 rounded"
                @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter v-if="shownavandfooter === true" />
  </div>
</template>

<script>
import Vue from "vue";
import CustomersRequests from "../../../requests/dashboard/sale/Customers";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  props: {
    shownavandfooter: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    snackbar: true,
    customers: [],
    search: "",
    loading:
      Vue.prototype.getters().getcustomers == null ||
        Vue.prototype.getters().getcustomers == "" ||
        Vue.prototype.getters().getcustomers == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center" },
      {
        text: Vue.prototype.langkeyword("NAME"),
        value: "name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("PHONE"),
        value: "phone",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ADDRESS"),
        value: "address",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    customersObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      name: "",
      phone: "",
      address: "",
      iqddebt: 0,
      usddebt: 0,
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
    setTimeout(() => {
      this.readData();
    }, 1000);
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      this.customersObject = {
        user_id: Vue.prototype.dashboardAuthId(),
        name: "",
        phone: "",
        address: "",
        iqddebt: 0,
        usddebt: 0,
      };
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.customersObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          name: "",
          phone: "",
          address: "",
          iqddebt: 0,
          usddebt: 0,
        },
        item
      );
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.customersObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          name: "",
          phone: "",
          address: "",
          iqddebt: 0,
          usddebt: 0,
        },
        item
      );
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.customersObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          name: "",
          phone: "",
          address: "",
          iqddebt: 0,
          usddebt: 0,
        },
        item
      );
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.customersObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            name: "",
            phone: "",
            address: "",
            iqddebt: 0,
            usddebt: 0,
          },
          this.defaultItem
        );
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.customersObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            name: "",
            phone: "",
            address: "",
            iqddebt: 0,
            usddebt: 0,
          },
          this.defaultItem
        );
        this.CURRENTFORM = "add";
      });
    },
    readData() {
      this.customers = this.isNormal() ? this.$store.getters.getcustomers.filter(c => { return c.id != 1 && c.user_id == this.userId() }) : this.$store.getters.getcustomers.filter(c => { return c.id != 1 })
      setTimeout(() => {
        if (!this.permissionExist("#view_customers")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
    saveCustomers() {
      this.validate(
        [
          this.customersObject.name,
          this.customersObject.phone,
          this.customersObject.address,
        ],
        () => {
          CustomersRequests.add(
            {
              addCustomers: true,
              name: this.customersObject.name,
              phone: this.customersObject.phone,
              address: this.customersObject.address,
              iqddebt: this.customersObject.iqddebt,
              usddebt: this.customersObject.usddebt,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.customers = res.data.data;
                this.readData();
                //this.customers = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateCustomers(customerId) {
      this.validate(
        [
          this.customersObject.name,
          this.customersObject.phone,
          this.customersObject.address,
        ],
        () => {
          CustomersRequests.edit(
            {
              editCustomers: true,
              customer_id: customerId,
              name: this.customersObject.name,
              phone: this.customersObject.phone,
              address: this.customersObject.address,
              iqddebt: this.customersObject.iqddebt,
              usddebt: this.customersObject.usddebt,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.customers = res.data.data;
                this.readData();
                //this.customers = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteCustomers(customerId) {
      CustomersRequests.delete(
        { deleteCustomers: true, customer_id: customerId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.customers = res.data.data;
            this.customers = res.data.data;
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
    viewProfile(item) {
      this.$router.push("/dashboard/sale/customers/" + item.id);
    },
  },
};
</script>
