<template>
  <div>
    <navbar></navbar>
    <v-container>

      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="privacies pa-2 mb-5" style="display:flex;justify-content:center;align-items: center;">
        <v-card outlined class="radius-10 col-12 mb-5" flat>
          <v-card-text>
            <v-container fluid>







              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('SALE') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('CURRENCY') }} </h3>
                  <v-radio-group hide-details class="text-main" v-model="maincurrencysale">
                    <v-radio class="text-main" :label="langkeyword('DINAR')" value="iqd"></v-radio>
                    <v-radio :label="langkeyword('DOLLAR')" value="usd"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="maininvoicetypesale">
                    <v-radio :label="langkeyword('CASH')" value="cash"></v-radio>
                    <v-radio :label="langkeyword('DEBT')" value="debt"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedollarsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedinarsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('UNITS') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideunitssale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DISCOUNT') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidediscountsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('PROFIT') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideprofitsale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideinvoicetypesale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DRIVERWAGE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedriverwagesale">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">













              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('BUY') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('CURRENCY') }} </h3>
                  <v-radio-group hide-details class="text-main" v-model="maincurrencybuy">
                    <v-radio class="text-main" :label="langkeyword('DINAR')" value="iqd"></v-radio>
                    <v-radio :label="langkeyword('DOLLAR')" value="usd"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="maininvoicetypebuy">
                    <v-radio :label="langkeyword('CASH')" value="cash"></v-radio>
                    <v-radio :label="langkeyword('DEBT')" value="debt"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedollarbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedinarbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('UNITS') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideunitsbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DISCOUNT') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidediscountbuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideinvoicetypebuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DRIVERWAGE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedriverwagebuy">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">











              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('EXPENSE') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('CURRENCY') }} </h3>
                  <v-radio-group hide-details class="text-main" v-model="maincurrencyexpense">
                    <v-radio class="text-main" :label="langkeyword('DINAR')" value="iqd"></v-radio>
                    <v-radio :label="langkeyword('DOLLAR')" value="usd"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('MAIN') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="maininvoicetypeexpense">
                    <v-radio :label="langkeyword('CASH')" value="cash"></v-radio>
                    <v-radio :label="langkeyword('DEBT')" value="debt"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedollarexpense">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedinarexpense">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('INVOICETYPE') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideinvoicetypeexpense">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">







              <v-row class="mb-1 mt-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-auto text-center">
                  <h1> {{ langkeyword('PRODUCT') }} </h1>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DOLLAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedollarproduct">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('DINAR') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hidedinarproduct">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" sm="6" md="6" lg="6" xl="4">
                  <h3> {{ langkeyword('HIDE') + '-' + langkeyword('UNITS') }} </h3>
                  <v-radio-group hide-details class="text-mode" v-model="hideunitsproduct">
                    <v-radio :label="langkeyword('YES')" value="yes"></v-radio>
                    <v-radio :label="langkeyword('NO')" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr style="width:75%;margin:auto;">









            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
      <br><br><br>

    </v-container>
    <dashboardFooter />
  </div>
</template>

<script>
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  props: ["items"],
  data() {
    return {
      maincurrencysale: this.$store.state.setting.maincurrencysale,
      maininvoicetypesale: this.$store.state.setting.maininvoicetypesale,
      hidediscountsale: this.$store.state.setting.hidediscountsale,
      hideunitssale: this.$store.state.setting.hideunitssale,
      hidedollarsale: this.$store.state.setting.hidedollarsale,
      hidedinarsale: this.$store.state.setting.hidedinarsale,
      hideprofitsale: this.$store.state.setting.hideprofitsale,
      hideinvoicetypesale: this.$store.state.setting.hideinvoicetypesale,
      hidesaveconfirmationsale: this.$store.state.setting.hidesaveconfirmationsale,
      hidedriverwagesale: this.$store.state.setting.hidedriverwagesale,

      maincurrencybuy: this.$store.state.setting.maincurrencybuy,
      maininvoicetypebuy: this.$store.state.setting.maininvoicetypebuy,
      hidediscountbuy: this.$store.state.setting.hidediscountbuy,
      hideunitsbuy: this.$store.state.setting.hideunitsbuy,
      hidedollarbuy: this.$store.state.setting.hidedollarbuy,
      hidedinarbuy: this.$store.state.setting.hidedinarbuy,
      hideinvoicetypebuy: this.$store.state.setting.hideinvoicetypebuy,
      hidedriverwagebuy: this.$store.state.setting.hidedriverwagebuy,

      maincurrencyexpense: this.$store.state.setting.maincurrencyexpense,
      maininvoicetypeexpense: this.$store.state.setting.maininvoicetypeexpense,
      hidedollarexpense: this.$store.state.setting.hidedollarexpense,
      hidedinarexpense: this.$store.state.setting.hidedinarexpense,
      hideinvoicetypeexpense: this.$store.state.setting.hideinvoicetypeexpense,

      hidedollarproduct: this.$store.state.setting.hidedollarproduct,
      hidedinarproduct: this.$store.state.setting.hidedinarproduct,
      hideunitsproduct: this.$store.state.setting.hideunitsproduct,


      error_msg: null,
      success_msg: null
    };
  },
  components: {
    navbar,
    dashboardFooter,
  },
  methods: {
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },

    darkmode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "dashboardTheme",
        this.$vuetify.theme.dark.toString()
      );
    },
  },
  mounted() {
    const theme = localStorage.getItem("dashboardTheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.switchBtn = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.switchBtn = false;
      }
    }
  },
};
</script>

<style>
.privacies {
  margin-top: 50px !important;
}

.theme--dark .dashboard .v-list {
  background: var(--another) !important;
}

.vue-swatches__trigger {
  border: 1px solid white !important;
}
</style>
