<template>
    <div>
        <navbar></navbar>
        <v-container fluid class="mt-4 mb-4">
            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>




            <v-row>
                <v-expansion-panels class="container mt-5" popout hover focusable>
                    <v-expansion-panel class="col-12 mode">
                        <v-expansion-panel-header expand-icon="mdi-transfer">
                            <h3>{{ langkeyword("TRANSFER") }}</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form class="row text-center">
                                <v-card class="col-12 ma-auto pa-3 rounded mb-3 mt-5 mt-1 text-center" outlined dense>
                                    <v-autocomplete @change="to = null" v-model="from"
                                        :items="treasures.filter(t => { return t.id != this.to })" :item-text="item => (lang() === 'ku' ? item.ku_name : '' || lang() === 'ar' ? item.ar_name : '' || lang() === 'en' ? item.en_name : '')
                                            + ' ـــــ ' +
                                            (lang() === 'ku' ? item.stores_ku_name : '' || lang() === 'ar' ? item.stores_ar_name : '' || lang() === 'en' ? item.stores_en_name : '')
                                            " item-value="id" clearable outlined class="nzar-font ma-1"
                                        :label="langkeyword('FROM')"></v-autocomplete>
                                    <v-autocomplete :disabled="from == '' || from === null" v-model="to"
                                        :items="treasures.filter(t => { return t.id != this.from })"
                                        :item-text="item => (lang() === 'ku' ? item.ku_name : '' || lang() === 'ar' ? item.ar_name : '' || lang() === 'en' ? item.en_name : '')
                                            + ' ـــــ ' +
                                            (lang() === 'ku' ? item.stores_ku_name : '' || lang() === 'ar' ? item.stores_ar_name : '' || lang() === 'en' ? item.stores_en_name : '')"
                                        item-value="id" clearable outlined class="nzar-font ma-1"
                                        :label="langkeyword('TO')"></v-autocomplete>
                                    <v-text-field type="number" min="0" hide-spin-buttons outlined hide-details
                                        class="nzar-font ma-1" v-model="amount_iqd"
                                        :label="langkeyword('AMOUNT') + ' ــــ ' + langkeyword('IQD')">
                                    </v-text-field>
                                    <br>
                                    <v-text-field type="number" min="0" hide-spin-buttons outlined hide-details
                                        class="nzar-font ma-1" v-model="amount_usd"
                                        :label="langkeyword('AMOUNT') + ' ــــ ' + langkeyword('USD')">
                                    </v-text-field>
                                    <v-btn color="success" large class="ma-1 pa-2" @click="transferTreasure()">
                                        <v-icon>mdi-transfer</v-icon>
                                        {{ langkeyword('TRANSFER') }}
                                    </v-btn>
                                </v-card>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>


            <v-row class="d-flex text-main justify-center">
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="treasures" :loading="loading" :search="search"
                        :sort-asc="true" sort-by="id" class="elevation-1 nzar-font radius-15 mode" :footer-props="{
                            showFirstLastPage: true,
                            prevIcon: 'mdi-arrow-left',
                            nextIcon: 'mdi-arrow-right',
                            'items-per-page-text': langkeyword('PERPAGE'),
                            'items-per-page-options': perPages(),
                        }" :items-per-page="10">
                        <template v-slot:top>
                            <v-toolbar flat class="radius-15">
                                <v-toolbar-title><v-icon>mdi-treasure-chest</v-icon>
                                    {{ langkeyword("TREASURES") }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="700px" persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="success"
                                            :style="permissionExist('#add_treasures') ? 'color: white;float:right' : 'display:none;'"
                                            class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                                            <v-icon> mdi-plus-circle </v-icon>
                                            {{ langkeyword("ADD") }}
                                        </v-btn>
                                    </template>
                                    <v-card class="radius-15 bg-mode">
                                        <v-card-title>
                                            <span class="nzar-font" v-if="isAdd() === true"><v-icon>
                                                    mdi-plus-circle</v-icon>
                                                {{ langkeyword("ADD") }}</span>
                                            <span class="nzar-font" v-if="isEdit() === true"><v-icon>
                                                    mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}</span>
                                        </v-card-title>
                                        <v-card-text class="radius-15">
                                            <v-container>
                                                <v-form>
                                                    <v-text-field class="nzar-font" append-icon="mdi-format-title"
                                                        v-model="treasuresObject.ku_name" outlined
                                                        :label="langkeyword('KU_NAME')">
                                                    </v-text-field>
                                                    <v-text-field class="nzar-font" append-icon="mdi-format-title"
                                                        v-model="treasuresObject.ar_name" outlined
                                                        :label="langkeyword('AR_NAME')">
                                                    </v-text-field>
                                                    <v-text-field class="nzar-font" append-icon="mdi-format-title"
                                                        v-model="treasuresObject.en_name" outlined
                                                        :label="langkeyword('EN_NAME')">
                                                    </v-text-field>
                                                    <v-text-field class="nzar-font" type="number" append-icon="mdi-cash"
                                                        v-model="treasuresObject.iqd_amount" outlined :label="langkeyword('AMOUNT') +
                                                            '  ' +
                                                            langkeyword('IQD')
                                                            ">
                                                    </v-text-field>

                                                    <v-text-field class="nzar-font" type="number" append-icon="mdi-cash"
                                                        v-model="treasuresObject.usd_amount" outlined :label="langkeyword('AMOUNT') +
                                                            '  ' +
                                                            langkeyword('USD')
                                                            ">
                                                    </v-text-field>
                                                    <v-select v-if="isAdd() === true || isEdit() === true"
                                                        append-icon="mdi-store" v-model="treasuresObject.store_id"
                                                        :items="stores" :item-text="langoptions('value') + '_name'"
                                                        item-value="id" outlined :label="langkeyword('STORE')"
                                                        :class="isNormal() ? 'd-none' : ''"></v-select>
                                                </v-form>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn class="bg-main white--text" v-if="isAdd() === true" dark
                                                @click="saveTreasures">
                                                <v-icon dark> mdi-plus-circle</v-icon>
                                                {{ langkeyword("SAVE") }}
                                            </v-btn>
                                            <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                                                @click="updateTreasures(treasuresObject.id)">
                                                <v-icon dark> mdi-pencil-circle</v-icon>
                                                {{ langkeyword("UPDATE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" dark @click="closeMainDialog">
                                                <v-icon dark>mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <v-icon>mdi-delete-circle</v-icon>
                                            {{ langkeyword("DELETE") }}
                                        </v-card-title>
                                        <v-card-text>
                                            {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="red lighten-2" dark
                                                @click="deleteTreasures(treasuresObject.id)">
                                                <v-icon>mdi-delete-circle</v-icon>
                                                {{ langkeyword("DELETE") }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                                <v-icon> mdi-close-circle</v-icon>
                                                {{ langkeyword("CLOSE") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-spacer></v-spacer>

                                <v-row>
                                    <v-col cols="12" md="10">
                                        <v-text-field v-if="permissionExist('#search_treasures')" class="nzar-font"
                                            v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                                            hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>


                        <template v-slot:[`item.iqd_amount`]="{ item }">
                            {{ dinar(item.iqd_amount) }}
                        </template>

                        <template v-slot:[`item.usd_amount`]="{ item }">
                            {{ dollar(item.usd_amount) }}
                        </template>



                        <template v-slot:[`item.edit`]="{ item }">
                            <v-icon v-if="permissionExist('#edit_treasures')" small
                                class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                                mdi-pencil-circle
                            </v-icon>
                            <v-icon v-if="permissionExist('#delete_treasures')" small
                                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                                mdi-delete-circle
                            </v-icon>
                            <v-icon v-if="permissionExist('#view_treasures')" small
                                class="mr-2 info white--text px-2 py-2 rounded" @click="viewTreasure(item)">
                                mdi-information
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
import Vue from "vue";
import TreasuresRequests from "../../../requests/dashboard/accounting/Treasures";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import { isNumber } from "chart.js/helpers";
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data: () => ({
        dialog: false,
        dialogDelete: false,
        snackbar: true,
        treasures: [],
        search: "",
        amount_iqd: 0,
        amount_usd: 0,
        from: '',
        to: '',
        loading:
            Vue.prototype.getters().gettreasures == null ||
                Vue.prototype.getters().gettreasures == "" ||
                Vue.prototype.getters().gettreasures == undefined
                ? true
                : false,
        error_msg: null,
        success_msg: null,
        headers: [
            { text: "#", value: "id", align: "center" },
            {
                text: Vue.prototype.langkeyword("NAME"),
                value: Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("STORE"),
                value: "stores_" + Vue.prototype.langoptions("value") + "_name",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("AMOUNT"),
                value: "iqd_amount",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("AMOUNT"),
                value: "usd_amount",
                align: "center",
            },
            {
                text: Vue.prototype.langkeyword("ACTIONS"),
                value: "edit",
                sortable: false,
                align: "center",
            },
        ],
        CURRENTFORM: "add",
        treasuresObject: {
            user_id: Vue.prototype.dashboardAuthId(),
            ku_name: "",
            ar_name: "",
            en_name: "",
            iqd_amount: 0,
            usd_amount: 0,
            store_id: Vue.prototype.state().getdashboardauth.store_id,
        },
    }),
    watch: {
        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    computed: {
        stores() {
            return this.$store.getters.getstores;
        },
    },
    methods: {
        isAdd() {
            if (this.CURRENTFORM === "add") {
                return true;
            } else {
                return false;
            }
        },
        isEdit() {
            if (this.CURRENTFORM === "edit") {
                return true;
            } else {
                return false;
            }
        },
        cleanMessages() {
            this.treasuresObject = {
                user_id: Vue.prototype.dashboardAuthId(),
                ku_name: "",
                ar_name: "",
                en_name: "",
                iqd_amount: 0,
                usd_amount: 0,
                store_id: Vue.prototype.state().getdashboardauth.store_id,
            };
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        validate(params, callback) {
            this.error_msg = null;
            params.forEach((param) => {
                if (param == "" || param == undefined || param == null) {
                    this.error_msg = this.langkeyword("DATA_REQUIRED");
                }
            });
            if (this.error_msg == null) {
                return callback();
            }
            this.cleanMessages();
        },
        editDialog(item) {
            this.CURRENTFORM = "edit";
            this.treasuresObject = Object.assign({
                user_id: Vue.prototype.dashboardAuthId(),
                ku_name: "",
                ar_name: "",
                en_name: "",
                iqd_amount: 0,
                usd_amount: 0,
                store_id: Vue.prototype.state().getdashboardauth.store_id,
            }, item);
            this.dialog = true;
        },
        lockDialog(item) {
            this.CURRENTFORM = "lock";
            this.treasuresObject = Object.assign({
                user_id: Vue.prototype.dashboardAuthId(),
                ku_name: "",
                ar_name: "",
                en_name: "",
                iqd_amount: 0,
                usd_amount: 0,
                store_id: Vue.prototype.state().getdashboardauth.store_id,
            }, item);
            this.dialog = true;
        },
        deleteDialog(item) {
            this.CURRENTFORM = "delete";
            this.treasuresObject = Object.assign({
                user_id: Vue.prototype.dashboardAuthId(),
                ku_name: "",
                ar_name: "",
                en_name: "",
                iqd_amount: 0,
                usd_amount: 0,
                store_id: Vue.prototype.state().getdashboardauth.store_id,
            }, item);
            this.dialogDelete = true;
        },
        closeMainDialog() {
            this.dialog = false;
            this.$nextTick(() => {
                this.treasuresObject = Object.assign({
                    user_id: Vue.prototype.dashboardAuthId(),
                    ku_name: "",
                    ar_name: "",
                    en_name: "",
                    iqd_amount: 0,
                    usd_amount: 0,
                    store_id: Vue.prototype.state().getdashboardauth.store_id,
                }, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.treasuresObject = Object.assign({
                    user_id: Vue.prototype.dashboardAuthId(),
                    ku_name: "",
                    ar_name: "",
                    en_name: "",
                    iqd_amount: 0,
                    usd_amount: 0,
                    store_id: Vue.prototype.state().getdashboardauth.store_id,
                }, this.defaultItem);
                this.CURRENTFORM = "add";
            });
        },
        readData() {
            this.treasures = this.$store.getters.gettreasures;
            setTimeout(() => {
                if (!this.permissionExist("#view_treasures")) {
                    this.$router.push("/dashboard/home");
                }
            }, 500);
        },
        saveTreasures() {
            this.validate(
                [
                    this.treasuresObject.ku_name,
                    this.treasuresObject.ar_name,
                    this.treasuresObject.en_name,
                    this.treasuresObject.store_id,
                ],
                () => {
                    TreasuresRequests.add(
                        {
                            addTreasures: true,
                            ku_name: this.treasuresObject.ku_name,
                            ar_name: this.treasuresObject.ar_name,
                            en_name: this.treasuresObject.en_name,
                            iqd_amount: this.treasuresObject.iqd_amount,
                            usd_amount: this.treasuresObject.usd_amount,
                            store_id: this.treasuresObject.store_id,
                            user_id: Vue.prototype.dashboardAuthId(),
                        },
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.treasures = res.data.data;

                                this.treasures = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        updateTreasures(TreasureId) {
            this.validate(
                [
                    this.treasuresObject.ku_name,
                    this.treasuresObject.ar_name,
                    this.treasuresObject.en_name,
                    this.treasuresObject.store_id,
                ],
                () => {
                    TreasuresRequests.edit(
                        {
                            editTreasures: true,
                            treasure_id: TreasureId,
                            ku_name: this.treasuresObject.ku_name,
                            ar_name: this.treasuresObject.ar_name,
                            en_name: this.treasuresObject.en_name,
                            iqd_amount: this.treasuresObject.iqd_amount,
                            usd_amount: this.treasuresObject.usd_amount,
                            store_id: this.treasuresObject.store_id,
                        },
                        (res) => {
                            if (res.data.status === false) {
                                this.error_msg = this.langkeyword(res.data.msg);
                            } else if (res.data.status === true) {
                                this.error_msg = null;
                                this.success_msg = this.langkeyword(res.data.msg);
                                this.$store.state.treasures = res.data.data;
                                this.treasures = res.data.data;
                            }
                            this.cleanMessages();
                        }
                    );
                }
            );
            this.closeMainDialog();
        },
        deleteTreasures(TreasureId) {
            TreasuresRequests.delete(
                { deleteTreasures: true, treasure_id: TreasureId },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.treasures = res.data.data;
                        this.treasures = res.data.data;
                    }
                }
            );
            this.cleanMessages();
            this.closeDeleteDialog();
        },
        transferTreasure() {
            if ((this.from != null && this.from != '' && this.to != null && this.to != '' && isNumber(this.amount_iqd) && isNumber(this.amount_usd)) && (this.amount_iqd != 0 || this.amount_usd != 0)) {
                TreasuresRequests.transfer(
                    {
                        transferTreasures: true,
                        from: this.from,
                        to: this.to,
                        amount_iqd: this.amount_iqd,
                        amount_usd: this.amount_usd,
                    },
                    (res) => {
                        if (res.data.status === false) {
                            this.error_msg = this.langkeyword(res.data.msg);
                        } else if (res.data.status === true) {
                            this.error_msg = null;
                            this.success_msg = this.langkeyword(res.data.msg);
                            this.$store.state.treasures = res.data.data;
                            this.treasures = res.data.data;
                            this.amount_iqd = 0;
                            this.amount_usd = 0;
                            this.from = '';
                            this.to = '';
                        }
                        this.cleanMessages();
                    }
                );
            }
        },
        viewTreasure(item) {
            this.$router.push("/dashboard/accounting/treasures/" + item.id);
        }
    },
};
</script>