<template>
    <div>
        <navbar></navbar>
        <v-container class="mb-4">
            <v-row class="text-center printable">
                <v-col cols="12 text-start mx-auto" sm="12" md="12" lg="12" xl="12">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{
                                langkeyword("PROFILE")
                                }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword("NAME") }} :
                                <span v-if="lang() === 'ku'">{{ treasureinfo.data.ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ treasureinfo.data.ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ treasureinfo.data.en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("STORE") }} :
                                <span v-if="lang() === 'ku'">{{ treasureinfo.data.stores_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ treasureinfo.data.stores_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ treasureinfo.data.stores_en_name }}</span>
                            </h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(treasureinfo.totalDinarBuy) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(treasureinfo.totalDinarBuyCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(treasureinfo.totalDinarBuyDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(treasureinfo.totalDinarBuyFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(treasureinfo.totalDollarBuy) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(treasureinfo.totalDollarBuyCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(treasureinfo.totalDollarBuyDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(treasureinfo.totalDollarBuyFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(treasureinfo.totalDinarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(treasureinfo.totalDinarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(treasureinfo.totalDinarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(treasureinfo.totalDinarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(treasureinfo.totalDollarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(treasureinfo.totalDollarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(treasureinfo.totalDollarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(treasureinfo.totalDollarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("PROFIT") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(treasureinfo.totaldollarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(treasureinfo.totaldollarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(treasureinfo.totaldollarprofitdebt) }}</h4>
                            <v-divider></v-divider>
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(treasureinfo.totaldinarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(treasureinfo.totaldinarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(treasureinfo.totaldinarprofitdebt) }}</h4>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
//import Vue from "vue"
import TreasuresRequests from "../../../requests/dashboard/accounting/Treasures"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data() {
        return {
            treasureinfo: {},
            error_msg: null,
            success_msg: null,
            barcode: '',
            quantity: 0,
            from: '',
            to: '',
            editmode: false
        }
    },
    watch: {
        options: {
            handler() {
                this.readData()
            },
        },
    },
    mounted() {
        this.readData()
    },
    computed: {
    },
    methods: {
        cleanMessages() {
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        readData() {
            TreasuresRequests.getOne(
                {
                    getOneTreasures: true,
                    treasure_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg)
                        setTimeout(() => {
                            this.$router.push("/*")
                        }, 1000)
                    } else if (res.data.status === true) {
                        this.error_msg = null
                        this.treasureinfo = res.data
                    }
                    this.cleanMessages()
                }
            ),
                setTimeout(() => {
                    if (!this.permissionExist("#view_treasures")) {
                        this.$router.push("/dashboard/home")
                    }
                }, 500)
        },
    },
}
</script>
<style scoped>
@media print {
    .notprintable {
        display: none
    }

    .printable {
        display: block
    }
}
</style>