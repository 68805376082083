import axios from 'axios'


export default {


    async save(params, callback) {
        return await axios.post("/app/dashboard/sale/fastsale/save", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async return(params, callback) {
        return await axios.post("/app/dashboard/sale/fastsale/return", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

}

