<template>
  <div>
    <navbar></navbar>
    <v-container class="mt-4 mb-4 p-4">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">


          <v-col cols="10" sm="8" md="4" lg="3" xl="3" class="mx-auto mode--text">
            <v-card outlined class="rounded-lg">
              <center>
                <h1 class="mb-2 mt-2 pa-2">{{ langkeyword("REPORT") }}</h1>
              </center>
            </v-card>
          </v-col>


          <v-expansion-panels :mandatory="dates.date1 != null && dates.date2 != null" popout hover focusable>
            <v-expansion-panel class="mode" style="border-radius:50px;">
              <v-expansion-panel-header class="mode" style="border-radius:50px;" v-slot="{ open }">
                <v-row no-gutters style="border-radius:50px;">
                  <v-col cols="4" style="border-radius:50px;">
                    {{ langkeyword("REPORT") }}
                  </v-col>
                  <v-col cols="8" style="border-radius:50px;" class="text--secondary">
                    <v-fade-transition leave-absolute>
                      <span v-if="open">{{ langkeyword("SELECTDATES") }}</span>
                      <v-row v-else no-gutters style="width: 100%">
                        <v-col cols="6">
                          {{ langkeyword("DATE1") }}: {{ dates.date1 || "" }}
                        </v-col>
                        <v-col cols="6">
                          {{ langkeyword("DATE2") }}: {{ dates.date2 || "" }}
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode" style="border-radius:50px;">
                <v-row justify="space-around" no-gutters>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-menu ref="date1Menu" :close-on-content-click="false" :return-value.sync="dates.date1" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined filled v-model="dates.date1" :label="langkeyword('DATE1')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.date1Menu.isActive = false">
                          {{ langkeyword("CANCEL") }}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.date1Menu.save(date)">
                          {{ langkeyword("OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-menu ref="date2Menu" :close-on-content-click="false" :return-value.sync="dates.date2" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined filled v-model="dates.date2" :label="langkeyword('DATE2')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.date2Menu.isActive = false">
                          {{ langkeyword("CANCEL") }}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.date2Menu.save(date)">
                          {{ langkeyword("OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-autocomplete v-model="company_id" :items="companies" item-text="name" item-value="id"
                      prepend-icon="mdi mdi-domain" :label="langkeyword('COMPANY')" dense clearable outlined
                      filled></v-autocomplete>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-autocomplete v-model="customer_id" :items="customers" item-text="name" item-value="id"
                      prepend-icon="mdi mdi-face-agent" :label="langkeyword('CUSTOMER')" dense clearable outlined
                      filled></v-autocomplete>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-autocomplete v-model="product_id" :items="products" :item-text="langoptions('value') + '_name'"
                      item-value="id" prepend-icon="mdi mdi-shopping" :label="langkeyword('PRODUCT')" dense clearable
                      outlined filled></v-autocomplete>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-autocomplete v-model="store_id" :items="stores"
                      :disabled="$store.state.dashboardauth.id != 1 && $store.state.dashboardauth.id != 2"
                      :item-text="lang() + '_name'" item-value="id" prepend-icon="mdi mdi-shopping"
                      :label="langkeyword('STORES')" dense clearable outlined filled></v-autocomplete>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-autocomplete v-model="user_id" :items="users.filter(u => { return u.store_id == this.store_id })"
                      :disabled="($store.state.dashboardauth.id != 1 && $store.state.dashboardauth.id != 2) || store_id == null"
                      :item-text="'name'" item-value="id" prepend-icon="mdi mdi-shopping" :label="langkeyword('USERS')"
                      dense clearable outlined filled></v-autocomplete>
                  </v-col>
                </v-row>

                <center>
                  <v-btn @click="saveReport" v-if="dates.date1 != null &&
                    dates.date2 != null &&
                    permissionExist('#search_generalreports')
                  " class="m-auto bg-main text-center white--text" x-large>
                    {{ langkeyword("GETREPORT") }}
                  </v-btn>
                </center>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="reportCards != false">




      <v-row class="text-center mt-2 mb-2">
        <v-col cols="12" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ma-auto text-center notprintable">
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showSale" :label="langkeyword('SALE')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showBuy" :label="langkeyword('BUY')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showExpense" :label="langkeyword('EXPENSE')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showBasicDebt" :label="langkeyword('BASICDEBT')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showDebt" :label="langkeyword('DEBT')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showReturnDebt" :label="langkeyword('RETURNDEBT')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showProfit" :label="langkeyword('PROFIT')"></v-checkbox></v-btn>
          <v-btn x-large class="ma-1 bg-main white--text" dark><v-checkbox color="white" class="white--text"
              v-model="showProduct" :label="langkeyword('PRODUCT')"></v-checkbox></v-btn>
        </v-col>
      </v-row>



      <v-row no-gutters>





        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showSale">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarsale) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsale }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarsalecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsalecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarsaledebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsaledebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dinar(reportData.totaldinarsalefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsalefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showSale">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarsale) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsale }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarsalecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsalecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarsaledebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsaledebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dollar(reportData.totaldollarsalefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsalefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>




        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showBuy">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarbuy) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuy }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarbuycash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuycash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarbuydebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuydebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dinar(reportData.totaldinarbuyfree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuyfree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showBuy">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarbuy) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuy }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarbuycash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuycash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarbuydebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuydebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dollar(reportData.totaldollarbuyfree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuyfree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>








        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showExpense">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("EXPENSE") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarexpense) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpense }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarexpensecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpensecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarexpensedebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpensedebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dinar(reportData.totaldinarexpensefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpensefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showExpense">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("EXPENSE") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarexpense) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpense }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarexpensecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpensecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarexpensedebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpensedebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dollar(reportData.totaldollarexpensefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpensefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>










        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showBasicDebt">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BASICDEBT") }}-{{ langkeyword("BUY") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(reportData.totalDollarBuyBasicDebt) }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(reportData.totalDinarBuyBasicDebt) }}
              </h4>
            </div>
          </v-card>
        </v-col>




        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showBasicDebt">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BASICDEBT") }}-{{ langkeyword("SALE") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(reportData.totalDollarSaleBasicDebt) }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(reportData.totalDinarSaleBasicDebt) }}
              </h4>
            </div>
          </v-card>
        </v-col>







        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showDebt">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("TOTALDEBT") }}-{{ langkeyword("BUY") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(reportData.totalDollarBuyBasicDebt +
                  reportData.totaldollarbuydebt)
                }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(reportData.totalDinarBuyBasicDebt + reportData.totaldinarbuydebt)
                }}
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showDebt">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("TOTALDEBT") }}-{{ langkeyword("SALE") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(reportData.totalDollarSaleBasicDebt +
                  reportData.totaldollarsaledebt)
                }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(reportData.totalDinarSaleBasicDebt + reportData.totaldinarsaledebt)
                }}
              </h4>
            </div>
          </v-card>
        </v-col>












        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showReturnDebt">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("RETURNDEBT") }}-{{ langkeyword("BUY") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarreturnbuydebt) }}
              </h4>
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarreturnbuydebt) }}
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showReturnDebt">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("RETURNDEBT") }}-{{ langkeyword("SALE") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarreturnsaledebt) }}
              </h4>
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarreturnsaledebt) }}
              </h4>
            </div>
          </v-card>
        </v-col>







        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showProfit">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("PROFIT") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarprofitcash +
                reportData.totaldollarprofitdebt)
                }}
              </h4>
              <h4>{{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarprofitcash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarprofitdebt) }}</h4>
              <v-divider></v-divider>
              <h4>{{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarprofitcash +
                reportData.totaldinarprofitdebt) }}
              </h4>
              <h4>{{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarprofitcash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarprofitdebt) }}</h4>
            </div>
          </v-card>
        </v-col>



        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3" v-if="showProduct">
          <v-card elevation="1" shaped outlined class="ma-1 reportcard">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("PRODUCT") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('QUANTITY') }}: {{ reportData.countproductsale }}
              </h4>
            </div>
          </v-card>
        </v-col>


      </v-row>













    </v-container>

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>
<script>
import GeneralReportRequests from "../../../requests/dashboard/accounting/GeneralReport";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    date: null,
    dates: {
      date1: null,
      date2: null,
    },
    showSale: true,
    showBuy: false,
    showExpense: false,
    showBasicDebt: false,
    showDebt: false,
    showReturnDebt: false,
    showProfit: false,
    showProduct: false,
    reportCards: false,
    user_id: null,
    product_id: null,
    store_id: null,
    company_id: null,
    customer_id: null,
    reportData: {},
  }),
  watch: {
    options: {
      handler() { },
    },
  },
  mounted() {
    if (this.$store.state.dashboardauth.role_id != 1 && this.$store.state.dashboardauth.role_id != 1) {
      this.user_id = this.$store.state.dashboardauth.id
    }
    setTimeout(() => {
      if (!this.permissionExist("#view_generalreports")) {
        this.$router.push("/dashboard/home");
      }
      this.getAllReport()
    }, 500);
  },
  computed: {
    companies() {
      return this.$store.getters.getcompanies;
    },
    customers() {
      return this.$store.getters.getcustomers;
    },
    products() {
      return this.$store.getters.getproducts;
    },
    stores() {
      return this.$store.getters.getstores;
    },
    users() {
      return this.$store.getters.getusers.filter(u => {
        return u.id != 1
      });
    },
  },
  methods: {
    saveReport() {
      GeneralReportRequests.byDate(
        {
          byDatePublicReportApp: true,
          date1: this.dates.date1,
          date2: this.dates.date2,
          user_id: this.user_id,
          product_id: this.product_id,
          company_id: this.company_id,
          customer_id: this.customer_id,
          store_id: this.store_id,
        },
        (res) => {
          this.reportCards = true;
          this.reportData = res.data;
        }
      );
    },
    getAllReport() {
      GeneralReportRequests.getAll(
        {
          getAllPublicReportApp: true
        },
        (res) => {
          this.reportCards = true;
          this.reportData = res.data;
        }
      );
    },
  },
};
</script>

<style scoped>
.reportcard {
  height: 15rem;
}
</style>