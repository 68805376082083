<template lang="">
    <div class="body">

        <v-container grid-list-xs>


            <center>
                <v-layout column class="form-forget">
                    <v-flex>
                        <v-col cols="12" sm="10" md="8" lg="6" xl="5">
                            <v-card class="radius-10" elevation="6" style="border:1px solid white">
                            <div class="text-center ">
                                <center>
                                    <span class="orange--text nzar-font" color="primary">{{error_auth}}</span>
                                    <v-img  contain   max-height="200" max-width="200"
                                        src="../../../assets/forget.svg">
                                    </v-img>
                                </center>

                            </div>

                            <v-form @submit.prevent="login">

                                <v-text-field outlined append-icon="mdi-email" :label="langkeyword('EMAIL')" 
                                
                                 required ref="input" v-model="email"
                                    class="mb-4 ma-2 nzar-font my-6 email-label" :disabled="disabled" :error-messages="error">
                                </v-text-field>


                            </v-form>
                                <v-btn x-large  class="bg-main rounded ma-2 py-5 text-none white--text nzar-font float-start" 
                                     @click="forget">
                                     <h3> {{langkeyword('AFTER')}}</h3>
                                </v-btn>
                                
                                <v-btn x-large class="text-forget nzar-font letter-spacing-0 white--text bg-main mt-2 rounded float-end" style="margin-left: -16px;"
                                    text @click="$router.push('/dashboard/auth/login')">
                                    <h2>{{langkeyword('BACK_TO_LOGIN')}}</h2>
                                </v-btn>
                        </v-card>
                        </v-col>
                    </v-flex>
                </v-layout>

            </center>



        </v-container>

    </div>
</template>
<script>
import forgetRequests from '../../../requests/dashboard/auth/Forget'
export default {
    data() {
        return {
            show: false,
            error: null,
            disabled: false,
            email: '',
            error_auth: null,
        }
    },
    methods: {
        ifAuthorized() {
            forgetRequests.ifAuthorized({
                isAuthCheck: true,
                user_id: this.dashboardAuthId(),
                token: this.dashboardAuthToken(),
            }, (res) => {
                if (res.data.status === true) {
                    this.$router.push('/dashboard/home')
                }
            })
        },
        forget() {
            if (this.email != null) {
                forgetRequests.authForget({
                    isForget: true,
                    email: this.email,
                }, (res) => {
                    if (res.data.status === false) {
                        this.error_auth = this.langkeyword(res.data.msg)
                    }
                    else if (res.data.status === true) {
                        this.$router.push('/dashboard/auth/reset?token=' + res.data.token)
                    }
                })
            }
        },
    },
    mounted() {
        this.ifAuthorized()
    }

}
</script>
<style>
@font-face {
    font-family: "nzar";
    src: local("nzar"),
        url(/assets/fonts/nzar.ttf) format("truetype");
}

.body {
    height: 100vh;
    background: #1d2125;
}

.card-layout {
    background: #1a1e22 !important;
}

.dark-blue {
    background: #1d2125 !important;
}

.text-style {
    font-family: "nzar" !important;
    color: aliceblue !important;
}

.nzar-font {
    font-family: "nzar" !important;
}

.v-label {
    font-size: 14px !important;
}

.form-forget {
    padding: 170px 0px;
}

.radius-10 {
    border-radius: 10px !important;
}

.radius-15 {
    border-radius: 15px !important;
}

.radius-circle {
    border-radius: 45% !important;
    padding: 25px 8px !important;

}

.text-forget {
    font-size: 10px !important;
}

@media only screen and (max-width: 600px) {
    .form-forget {
        padding: 183px 0px !important;
    }
}
</style>