<template>
  <div>
    <v-footer dark fixed>
      <v-col class="text-center text-main ma-auto" cols="8" md="8" dir="ltr">
        <span style="font-size:1.2rem;">
          <strong class="text-main">
            {{ new Date().getFullYear() }} -
            <a target="_blank" class="p-2 text-decoration-none text-main" href="https://garduny.dev/">{{
              appDeveloperName() }}
              <v-icon class="text-main" style="font-size:1.8rem;">mdi mdi-laptop-account</v-icon>
            </a>
          </strong>
        </span>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {

  }
};
</script>