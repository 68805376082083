<template lang="">
    <div >
        <navbar></navbar>


<v-row class="alert mt-4">
<v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg!=null">
<v-alert
dense
  type="warning"
>{{error_msg}}</v-alert>
</v-col>

<v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg!=null">
<v-alert
dense
  type="success"
>{{success_msg}}</v-alert>
</v-col>
</v-row>

<v-row class="setting pa-4" style="display:flex;justify-content:center" >
            <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="ltr"
                    v-model="englishname"
                    :label="langkeyword('EN_NAME')"
                    required
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeEnglishName()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

                        <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="rtl"
                    v-model="kurdishname"
                    :label="langkeyword('KU_NAME')"
                    required
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeKurdishName()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

            <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="rtl"
                    v-model="arabicname"
                    :label="langkeyword('AR_NAME')"
                    required
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeArabicName()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>




            <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="ltr"
                    v-model="englishdevelopername"
                    :label="langkeyword('DEVELOPER_ENGLISH_NAME')"
                    required
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeEnglishDeveloperName()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

                        <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="rtl"
                    v-model="kurdishdevelopername"
                    :label="langkeyword('DEVELOPER_KURDISH_NAME')"
                    required
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeKurdishDeveloperName()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

            <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="rtl"
                    v-model="arabicdevelopername"
                    :label="langkeyword('DEVELOPER_ARABIC_NAME')"
                    required
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeArabicDeveloperName()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>





            <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    @submit.prevent="changeLogo"
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-file-input
                    filled
                    dense
                    v-model="favicon"
                    id="settingFavicon"
                    name="favicon"
                    required
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    :label="langkeyword('FAVICON')">
                    </v-file-input>

                    <v-btn
                    :disabled="favicon==null"
                    small
                    type="submit"
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeFavicon()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


                        <v-col lg="4"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    @submit.prevent="changeLogo"
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-file-input
                    filled
                    dense
                    accept="image/png, image/jpeg, image/bmp, image/svg"
                    prepend-icon="mdi-camera"
                    v-model="logo"
                    id="settingLogo"
                    name="logo"
                    required
                    :label="langkeyword('LOGO')">
                    </v-file-input>

                    <v-btn
                    :disabled="logo==null"
                    small
                    type="submit"
                  
                    class="bg-main m-auto white--text pa-4"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

                        <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-select
                    filled
                    dense
                    v-model="lang"
                    :items="languages"
                    item-text="name"
                    item-value="value"
:label="langkeyword('LANGUAGE') + '  |'+defaultLangName()">
</v-select>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeLang()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="ltr"
                        :label="langkeyword('EMAIL')"
                        v-model="email"
                        prepend-icon="mdi-email"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeEmail()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>
            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="ltr"
                        v-model="phone1"
                        :label="langkeyword('PHONE1')"
                        prepend-icon="mdi-phone"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changePhone1()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="ltr"
                        v-model="phone2"
                        :label="langkeyword('PHONE2')"
                        prepend-icon="mdi-phone"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changePhone2()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>
        
            
            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    class="text-xs-center"
                        v-model="ku_address"
                        :label="langkeyword('ADDRESS')+'-'+langkeyword('KURDISH')"
                        append-icon="mdi-map-marker"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeAddressKurdish()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    class="text-xs-center"
                        v-model="ar_address"
                        :label="langkeyword('ADDRESS')+'-'+langkeyword('ARABIC')"
                        append-icon="mdi-map-marker"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeAddressArabic()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    class="text-xs-center"
                        v-model="en_address"
                        :label="langkeyword('ADDRESS')+'-'+langkeyword('ENGLISH')"
                        append-icon="mdi-map-marker"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeAddressEnglish()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    class="text-xs-center"
                        v-model="licensenumber"
                        :label="langkeyword('LICENSENUMBER')"
                        append-icon="mdi-license"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeLicenseNumber()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


            <v-col lg="4" cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-text-field
                    dense
                    filled
                    dir="ltr"
                    class="text-xs-center"
                        v-model="website"
                        :label="langkeyword('WEBSITE')"
                        prepend-icon="mdi-web"
                    ></v-text-field>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeWebsite()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>


        </v-row>


        
        <v-row class="setting pa-4 mb-16" style="display:flex;justify-content:center" >
        <v-col lg="6"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
             <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-textarea
                    filled
                    dir="ltr"
                    clearable
                    v-model="englishabout"
                    clear-icon="mdi-close-circle"
                    :label="langkeyword('EN_ABOUT')"
                    ></v-textarea>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeAboutEnglish()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

             <v-col lg="6"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
             <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-textarea
                    dir="rtl"
                    filled
                    clearable
                    v-model="kurdishabout"
                    clear-icon="mdi-close-circle"
                    :label="langkeyword('KU_ABOUT')"
                    ></v-textarea>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeAboutKurdish()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>

             <v-col lg="6"  cols="11" class="col-11 col-sm-6 col-md-6 col-lg-4 col-xl-4">
             <v-card class="px-5 py-5 rounded-lg">
                <v-form
                    ref="form"
                    lazy-validation
                    class="text-center"
                >
                    <v-textarea
                    dir="rtl"
                    filled
                    clearable
                    v-model="arabicabout"
                    clear-icon="mdi-close-circle"
                    :label="langkeyword('AR_ABOUT')"
                    ></v-textarea>

                    <v-btn
                    small
                  
                    class="bg-main m-auto white--text pa-4"
                    @click="changeAboutArabic()"
                    >
                    <v-icon>mdi-pen</v-icon>
                    {{langkeyword('UPDATE')}}
                    </v-btn>

                </v-form>
                </v-card>
            </v-col>
        </v-row>
   

        <dashboardFooter/>
    </div>
</template>

<script>
import settingsRequests from "../../../requests/dashboard/system/Settings";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";

export default {
  props: ["items"],
  data() {
    return {
      languages: [
        { name: this.langkeyword("ENGLISH"), value: "en" },
        { name: this.langkeyword("KURDISH"), value: "ku" },
        { name: this.langkeyword("ARABIC"), value: "ar" },
      ],
      email: this.$store.state.setting.email,
      phone1: this.$store.state.setting.phone1,
      phone2: this.$store.state.setting.phone2,
      ku_address: this.$store.state.setting.ku_address,
      ar_address: this.$store.state.setting.ar_address,
      en_address: this.$store.state.setting.en_address,
      licensenumber: this.$store.state.setting.licensenumber,
      website: this.$store.state.setting.website,
      kurdishname: this.$store.state.setting.ku_name,
      arabicname: this.$store.state.setting.ar_name,
      englishname: this.$store.state.setting.en_name,
      kurdishdevelopername: this.$store.state.setting.ku_developername,
      arabicdevelopername: this.$store.state.setting.ar_developername,
      englishdevelopername: this.$store.state.setting.en_developername,
      kurdishabout: this.$store.state.setting.ku_about,
      arabicabout: this.$store.state.setting.ar_about,
      englishabout: this.$store.state.setting.en_about,
      lang: this.$store.state.setting.lang,
      logo: null,
      favicon: null,
      saveColor: localStorage.getItem("bg"),
      switchBtn: false,
      fonts: ["Rabar", "Rudaw", "NRT"],
      selectFont: "",
      sfont: "",
      error_msg: null,
      success_msg: null,
      sidecolor: this.$store.getters.sidebar_color,
      textcolor: this.$store.getters.navbar_color,
      swatches: [
        "#343A40",
        "#27293d",
        "#413C69",
        "#16C79A",
        "#7579E7",
        "#52575D",
        "#1B6CA8",
        "#30475E",
        "#4D80E4",
        "#F35588",
        "#865858",
      ],
    };
  },
  components: {
    navbar,
    dashboardFooter,
  },
  methods: {
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },

    changeKurdishName() {
      settingsRequests.changeKurdishName(
        { changeNameKurdish: true, kurdishname: this.kurdishname },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeEnglishName() {
      settingsRequests.changeEnglishName(
        { changeNameEnglish: true, englishname: this.englishname },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeArabicName() {
      settingsRequests.changeArabicName(
        { changeNameArabic: true, arabicname: this.arabicname },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },

    changeKurdishDeveloperName() {
      settingsRequests.changeKurdishDeveloperName(
        {
          changeDeveloperNameKurdish: true,
          kurdishdevelopername: this.kurdishdevelopername,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeEnglishDeveloperName() {
      settingsRequests.changeEnglishDeveloperName(
        {
          changeDeveloperNameEnglish: true,
          englishdevelopername: this.englishdevelopername,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeArabicDeveloperName() {
      settingsRequests.changeArabicDeveloperName(
        {
          changeDeveloperNameArabic: true,
          arabicdevelopername: this.arabicdevelopername,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },

    changeLang() {
      settingsRequests.changeLang(
        { changeLang: true, lang: this.lang },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeEmail() {
      settingsRequests.changeEmail(
        { changeEmail: true, email: this.email },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changePhone1() {
      settingsRequests.changePhone1(
        { changePhone1: true, phone1: this.phone1 },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changePhone2() {
      settingsRequests.changePhone2(
        { changePhone2: true, phone2: this.phone2 },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeAddressKurdish() {
      settingsRequests.changeAddressKurdish(
        { changeAddressKurdish: true, ku_address: this.ku_address },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeAddressArabic() {
      settingsRequests.changeAddressArabic(
        { changeAddressArabic: true, ar_address: this.ar_address },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeAddressEnglish() {
      settingsRequests.changeAddressEnglish(
        { changeAddressEnglish: true, en_address: this.en_address },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeLicenseNumber() {
      settingsRequests.changeLicenseNumber(
        { changeLicenseNumber: true, licensenumber: this.licensenumber },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeWebsite() {
      settingsRequests.changeWebsite(
        { changeWebsite: true, website: this.website },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeAboutKurdish() {
      settingsRequests.changeAboutKurdish(
        { changeAboutKurdish: true, kurdishabout: this.kurdishabout },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeAboutArabic() {
      settingsRequests.changeAboutArabic(
        { changeAboutArabic: true, arabicabout: this.arabicabout },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeAboutEnglish() {
      settingsRequests.changeAboutEnglish(
        { changeAboutEnglish: true, englishabout: this.englishabout },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.setting = res.data.data;
          }
          this.cleanMessages();
        }
      );
    },
    changeLogo() {
      const filename = document.getElementById("settingLogo").files[0];
      const logoParams = new FormData();
      logoParams.append("changeLogo", true);
      logoParams.append("logo", filename);
      const config = {
        headers: {
          "content-type": `multipart/form-data; boundary=${logoParams._boundary}`,
        },
      };
      settingsRequests.changeLogo(logoParams, config, (res) => {
        if (res.data.status === false) {
          this.error_msg = this.langkeyword(res.data.msg);
        } else if (res.data.status === true) {
          document.getElementById("settingLogo").value = null;
          this.error_msg = null;
          this.success_msg = this.langkeyword(res.data.msg);
          this.$store.state.dashboardauth = res.data.data;
          this.logo = null;
        }
        this.cleanMessages();
      });
    },
    changeFavicon() {
      const filename = document.getElementById("settingFavicon").files[0];
      const faviconParams = new FormData();
      faviconParams.append("changeFavicon", true);
      faviconParams.append("favicon", filename);
      const config = {
        headers: {
          "content-type": `multipart/form-data; boundary=${faviconParams._boundary}`,
        },
      };
      settingsRequests.changeFavicon(faviconParams, config, (res) => {
        if (res.data.status === false) {
          this.error_msg = this.langkeyword(res.data.msg);
        } else if (res.data.status === true) {
          document.getElementById("settingLogo").value = null;
          this.error_msg = null;
          this.success_msg = this.langkeyword(res.data.msg);
          this.$store.state.dashboardauth = res.data.data;
          this.favicon = null;
        }
        this.cleanMessages();
      });
    },
    defaultLangName() {
      if (this.lang === "ku") {
        return this.langkeyword("KURDISH");
      } else if (this.lang === "ar") {
        return this.langkeyword("ARABIC");
      } else if (this.lang === "en") {
        return this.langkeyword("ENGLISH");
      }
    },

    darkmode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "dashboardTheme",
        this.$vuetify.theme.dark.toString()
      );
    },
  },
  mounted() {
    const theme = localStorage.getItem("dashboardTheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.switchBtn = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.switchBtn = false;
      }
    }
  },
};
</script>

<style>
.setting {
  margin-top: 50px !important;
}

.theme--dark .dashboard .v-list {
  background: var(--another) !important;
}

.vue-swatches__trigger {
  border: 1px solid white !important;
}
</style>
