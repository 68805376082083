<template lang="">
    <div class="body">

        <v-container>
 

            <center>
                <v-layout class="form">
                    <v-flex>

                      <v-col cols="12" sm="10" md="8" lg="6" xl="5">
                      <v-card class="radius-10" elevation="6" style="border:1px solid white">
                            <div class="text-center ">
                                <center>
                                    <v-alert v-if="false" dense border="left" text class="alert" color="white"  dismissible>
                                        {{langkeyword('PASSWORD_UPDATED')}}
                                    </v-alert>
                                        <span class="orange--text nzar-font pa-2" dark>{{error_auth}}</span>
                                        <v-img v-if="this.$store.state.setting.logo!=null" style="object-fit:contain" class="mb-2 my-2 radius-circle" max-height="200"
                                        max-width="200"  :src="uploadPath(this.$store.state.setting.logo)">
                                        </v-img>
                                        <v-img v-if="this.$store.state.setting.logo==null" style="object-fit:contain" class="mb-2 my-2 radius-circle" max-height="200"
                                            max-width="200" src="../../../assets/img/default-logo.png">
                                        </v-img>
                                </center>

                                <h3 class="d-inline-block mb-8 my-3 text-style black--text">  {{langkeyword('WELCOME')}}</h3>
                            </div>

                            <v-form>

                                <v-text-field append-icon="mdi-account" :label="langkeyword('USERNAME_OR_EMAIL')" required ref="input"
                                    v-model="username" center  outlined class="mb-4 nzar-font email-label pa-3" :disabled="disabled"
                                    :error-messages="error">
                                </v-text-field>

                                <v-text-field outlined class="mb-3 nzar-font pa-3" v-model="password"
                                    :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" :label="langkeyword('PASSWORD')"
                                    name="password" :type="show ? 'input' : 'password'"
                                    @click:append="show = !show" :error-messages="passErr" ref="pass" />

                                    <v-btn x-large class="login-button radius-10 nzar-font white--text bg-main" 
                                    @click="login">
                                    <h2 class="">{{langkeyword('LOGIN')}}</h2>
                                </v-btn>  
                        <br>
                                <v-btn x-large class="text-forget nzar-font letter-spacing-0 mb-2 mt-5" style="margin-left: -16px;"
                                    color="dark" text @click="$router.push('/dashboard/auth/forget')">
                                    <h2>{{langkeyword('FORGET_PASSWORD')}}</h2>
                                </v-btn>
                            </v-form>
                      </v-card>
                    </v-col>
                    </v-flex>
                </v-layout>

            </center>



        </v-container>

    </div>
</template>
<script>
import loginRequests from "../../../requests/dashboard/auth/Login";
export default {
  data() {
    return {
      show: false,
      error: null,
      passErr: null,
      disabled: false,
      username: localStorage.getItem("dashboardauth-username"),
      password: localStorage.getItem("dashboardauth-password"),
      num: 0,
      error_auth: null,
    };
  },
  methods: {
    ifAuthorized() {
      loginRequests.ifAuthorized(
        {
          isAuthCheck: true,
          user_id: this.dashboardAuthId(),
          token: this.dashboardAuthToken(),
        },
        (res) => {
          if (res.data.status === true) {
            this.$router.push("/dashboard/home");
          }
        }
      );
    },
    login() {
      loginRequests.authLogin(
        {
          isLogin: true,
          user_id: this.username,
          pass: this.password,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_auth = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            localStorage.setItem("dashboardauthId", res.data.data.id);
            localStorage.setItem("dashboardauthToken", res.data.token);
            localStorage.setItem("dashboardauth-username", this.username);
            localStorage.setItem("dashboardauth-password", this.password);
            this.$router.push("/dashboard/home");
          }
        }
      );
    },
  },
  mounted() {
    this.ifAuthorized();
  },
};
</script>
<style>
@font-face {
  font-family: "nzar";
  src: local("nzar"), url(/assets/fonts/nzar.ttf) format("truetype");
}

.body {
  height: 100vh;
  background: aliceblue !important;
}

.card-layout {
  background: aliceblue !important;
}


.nzar-font {
  font-family: "nzar" !important;
}

.alert {
  font-family: "nzar" !important;
  font-size: 12px !important;
}

.v-label {
  font-size: 14px !important;
}

.form {
  padding: 100px 0px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-circle {
  border-radius: 45% !important;
  padding: 25px 8px !important;
}

.text-forget {
  font-size: 10px !important;
}

.login-button {
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .form {
    padding: 53px 0px !important;
  }
}
</style>