import axios from 'axios'


export default {

    async changeKurdishName(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changenamekurdish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeArabicName(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changenamearabic", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeEnglishName(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changenameenglish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },




    async changeKurdishDeveloperName(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changedevelopernamekurdish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeArabicDeveloperName(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changedevelopernamearabic", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeEnglishDeveloperName(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changedevelopernameenglish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async changeLang(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changelang", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeEmail(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeemail", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changePhone1(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changephone1", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changePhone2(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changephone2", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeAddressKurdish(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeaddresskurdish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeAddressArabic(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeaddressarabic", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeAddressEnglish(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeaddressenglish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeLicenseNumber(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changelicensenumber", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeWebsite(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changewebsite", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeAboutKurdish(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeaboutkurdish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeAboutEnglish(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeaboutenglish", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeAboutArabic(params, callback) {
        return await axios.post("/app/dashboard/system/settings/changeaboutarabic", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async changeLogo(params, config, callback) {
        return await axios.post("/app/dashboard/system/settings/changelogo", params, config)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async changeFavicon(params, config, callback) {
        return await axios.post("/app/dashboard/system/settings/changefavicon", params, config)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

}

