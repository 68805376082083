<template>
  <div>


    <div class="centered">
      <v-progress-circular v-if="!loadApp" :size="70" :width="7" style="color: #615bff"
        indeterminate></v-progress-circular>
    </div>


    <v-app id="inspire" v-if="loadApp">
      <v-main class="dashboard">
        <router-view :key="$route.path"></router-view>
      </v-main>
    </v-app>


  </div>
</template>


<script>
import Vue from "vue";
import AppRequests from "./requests/App";
import MyHelpers from "./helpers";
import MenuRequests from "./requests/dashboard/system/Menus";
export default {
  data() {
    return {
      metasList: [],
      appTitle: null,
      app_style: false,
      isAuth: false,
      loadApp: false,
    };
  },
  computed: {},
  methods: {
    load() {
      // this.$forceUpdate
      this.$store.commit("fetchData");

      MenuRequests.getByPermissions(
        {
          getByPermissions: true,
          permissions: this.$store.state.dashboardauth.roles_permissions,
        },
        (res) => {
          if (res.data.status === true) {

            this.$store.state.menusbypermissions = res.data.data;
          }
        }
      );


      if (
        localStorage.getItem("lang") == undefined ||
        localStorage.getItem("lang") == "undefined" ||
        localStorage.getItem("lang") == null ||
        localStorage.getItem("lang") == ""
      ) {
        AppRequests.firstFetch({ isLoadApp: true }, (res) => {
          if (res.data.status === true) {
            Vue.use(MyHelpers);
            localStorage.setItem("lang", res.data.fetchsetting.lang);
            this.$store.state.setting = res.data.fetchsetting;
            this.$store.state.actlang = res.data.fetchsetting.lang;

            MenuRequests.getByPermissions(
              {
                getByPermissions: true,
                permissions: this.$store.state.dashboardauth.roles_permissions,
              },
              (res) => {
                if (res.data.status === true) {

                  this.$store.state.menusbypermissions = res.data.data;
                }
              }
            );


          }
        });
      } else {
        this.$store.state.actlang = localStorage.getItem("lang");
        Vue.use(MyHelpers);
      }
      setTimeout(() => {
        this.fetchDatas();
      }, 500);
    },
    appdirectionLang() {
      return this.directionLang();
    },

    fetchDatas() {
      AppRequests.firstFetch({ isLoadApp: true }, (res) => {
        if (res.data.status === true) {
          this.$store.state.setting = res.data.fetchsetting;
          document.getElementById("appfavicon").href = this.uploadPath(
            this.$store.state.setting.favicon
          );
          this.metasAndHeaders();
          this.loadApp = true;
        }
      });
    },

    metasAndHeaders() {
      if (this.lang() === "en") {
        document.getElementById("apptitle").innerHTML =
          this.$store.state.setting.en_name;
        this.appTitle = this.$store.state.setting.en_name;
      }
      if (this.lang() === "ar") {
        document.getElementById("apptitle").innerHTML =
          this.$store.state.setting.ar_name;
        this.appTitle = this.$store.state.setting.ar_name;
      }
      if (this.lang() === "ku") {
        document.getElementById("apptitle").innerHTML =
          this.$store.state.setting.ku_name;
        this.appTitle = this.$store.state.setting.ku_name;
      }
      if (
        window.location.pathname.includes("/Dashboard") ||
        window.location.pathname.includes("/dashboard")
      ) {
        this.app_style = true;
      } else {
        //  this.$store.state.metas.forEach(element => {
        //    this.metasList.push({name:'keywords',content:element.ku_keywords})
        //    this.metasList.push({name:'keywords',content:element.ar_keywords})
        //    this.metasList.push({name:'keywords',content:element.en_keywords})
        //    this.metasList.push({name:'description',content:element.ku_content})
        //    this.metasList.push({name:'description',content:element.ar_content})
        //    this.metasList.push({name:'description',content:element.en_content})
        //  })
      }
    },
  },
  mounted() {
    this.load();
  },

  created() {
    // document.body.classList.add('body');

    if (this.$vuetify.theme.dark == true) {
      //   this.$store.commit("set_body_color", "#1E1E2F");
    } else {
      //  this.$store.commit("set_body_color", "#EEEDF7");
    }
  },
};
</script>


<style scoped>
@font-face {
  font-family: "nzar";
  src: local("nzar"), url(/assets/fonts/nzar.ttf) format("truetype");
}

.txtarea .v-input__control .v-input__slot::before {
  border: none !important;
  border-style: none !important;
}

.txtarea .v-input__control .v-input__slot::after {
  border: none !important;
  border-style: none !important;
}

.v-btn {
  letter-spacing: 0 !important;
}

.v-dialog {
  box-shadow: none !important;
}

.v-overlay__scrim {
  background: #1d2125 !important;
}

.nzar-font {
  font-family: "nzar" !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-circle {
  border-radius: 50% !important;
}

.v-tab {
  letter-spacing: normal !important;
}

.isltr .v-text-field .v-label {
  right: unset !important;
}

.isltr .v-list-item__content {
  text-align: left !important;
}

.router-link-active {
  text-decoration: none !important;
}

/*  client login and register color */
.theme--light .client_auth {
  background: linear-gradient(to right, #43cea2, #185a9d) !important;
}

.theme--dark .client_auth .v-card {
  background: #2e3651 !important;
}

.theme--light .client_auth .v-card .cancel_btn {
  color: #1976d2 !important;
}

.theme--dark .client_auth .v-otp-input .v-input .v-input__control .v-input__slot {
  background: #1d2125 !important;
}

.theme--dark .v-dialog .v-card {
  background: #1a1e22 !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>