import axios from 'axios'


export default {

    async getAll(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/getall", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async getAllByCompany(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/getallbycompany", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async add(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/add", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async edit(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/edit", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async delete(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/delete", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async shipping(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/shipping", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async getOne(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/getone", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async changeAvatar(params, config, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/changeavatar", params, config)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async discount(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/discount", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async driverwage(params, callback) {
        return await axios.post("/app/dashboard/buy/companyinvoices/driverwage", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


}

