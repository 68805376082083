<template>
  <div>loading ...</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$router.push('/dashboard/home')
    }, 200);
  },
};
</script>