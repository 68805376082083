<template lang="">
    <div class="body">

        <v-container grid-list-xs>


            <center>
                <v-layout column class="form-reset">
                    <v-flex>
                        <v-col cols="12" sm="10" md="8" lg="6" xl="5">
                            <v-card class="radius-10" elevation="6" style="border:1px solid white">
                            <div class="text-center ">
                                <center>
                                    <span class="orange--text nzar-font" color="primary">{{error_auth}}</span>
                                    <v-img contain max-height="200" max-width="200" src="../../../assets/reset.svg">
                                    </v-img>
                                </center>
                            </div>

                            <v-form @submit.prevent="reset()">

                                <v-text-field outlined type="number" append-icon="mdi-numeric" :label="langkeyword('CODE')"
                                    required   class="code mb-2 ma-2 nzar-font my-6 " v-model="code"
                                    :disabled="disabled" :error-messages="error" ref="code">
                                </v-text-field>

                                 <v-text-field outlined class="mb-7 ma-2 nzar-font" v-model="newpass"
                                    :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" :label="langkeyword('NEWPASSWORD')"
                                    name="newpass" :type="show ? 'input' : 'password'" hide-details="auto"
                                    @click:append="show = !show" :error-messages="passErr" ref="pass" />


                            </v-form>


                            <div class="d-flex justify-space-between ">


                                <v-btn x-large class="py-5 text-none white--text nzar-font bg-main rounded ma-2 float-start" 
                                    @click="reset()">
                                   {{langkeyword('UPDATE')}}

                                </v-btn>

                                <v-btn x-large class="text-reset white--text nzar-font letter-spacing-0 bg-main rounded ma-2 float-end" style="margin-left: -16px;"
                                     text @click="$router.push('/dashboard/auth/forget')">
                                   {{langkeyword('IS_CODE_NOT_SENT')}}
                                </v-btn>

                            </div>
                        </v-card>
                        </v-col>
                    </v-flex>
                </v-layout>

            </center>



        </v-container>

    </div>
</template>
<script>
import resetRequests from '../../../requests/dashboard/auth/Reset'
export default {
    data() {
        return {
            show: false,
            error: null,
            passErr: null,
            disabled: false,
            code: '',
            token: this.$route.query.token,
            newpass: '',
            error_auth: null
        }
    },
    methods: {
        ifAuthorized() {
            resetRequests.ifAuthorized({
                isAuthCheck: true,
                user_id: this.dashboardAuthId(),
                token: this.dashboardAuthToken(),
            }, (res) => {
                if (res.data.status === true) {
                    this.$router.push('/dashboard/home')
                }
            })
        },
        reset() {
            resetRequests.authReset({
                isReset: true,
                code: this.code,
                newpass: this.newpass,
                token: this.token,
            }, (res) => {
                if (res.data.status === false) {
                    this.error_auth = this.langkeyword(res.data.msg)
                }
                else if (res.data.status === true) {
                    this.$router.push('/dashboard/auth/login')
                }
            })

        },
    },
    mounted() {
        this.ifAuthorized()
    }

}
</script>
<style>
@font-face {
    font-family: "nzar";
    src: local("nzar"),
        url(/assets/fonts/nzar.ttf) format("truetype");
}

.body {
    height: 100vh;
    background: #1d2125;
}

.card-layout {
    background: #1a1e22 !important;
}

.dark-blue {
    background: #1d2125 !important;
}

.text-style {
    font-family: "nzar" !important;
    color: aliceblue !important;
}

.nzar-font {
    font-family: "nzar" !important;
}

/* input  number */
.code input[type='number'] {
    -moz-appearance: textfield;
}

.code input::-webkit-outer-spin-button,
.code input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.v-label {
    font-size: 14px !important;
}

.form-reset {
    padding: 115px 0px;
}

.radius-10 {
    border-radius: 10px !important;
}

.radius-15 {
    border-radius: 15px !important;
}

.radius-circle {
    border-radius: 45% !important;
    padding: 25px 8px !important;

}

.text-reset {
    font-size: 20px !important;
}

@media only screen and (max-width: 600px) {
    .form-reset {
        padding: 123px 0px !important;
    }
}
</style>